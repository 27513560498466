import React from "react";

import "./Loading.scss";

export type Props = {
  isLoading?: boolean;
};

const Component: React.FC<Props> = ({ isLoading = true }) => {
  if (!isLoading) return null;

  return <div className="loading-indicator" aria-label="Loading..." />;
};

export default Component;
