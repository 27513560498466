import React from "react";

import "./Form.scss";

import { useFormikContext, Form, Field } from "formik";
import { FormModel } from "./Form.model";
// import { FormModel, TypeFaceOption } from "./Form.model";
import { CustomSelect, Loading } from "components";
// import { CustomSelect } from "components/CustomSelect";

type FormInnerProps = {
  onReset?: () => void;
};

// const typeFaceOptions: { [key in TypeFaceOption]: string } = {
//   classic: "Classic",
//   funky: "Funky",
//   fun: "Fun",
// };

const FormInner: React.FC<FormInnerProps> = ({ onReset }) => {
  const {
    // values,
    // setFieldValue,
    // setFieldTouched,
    isValid,
    isSubmitting,
    handleReset,
  } = useFormikContext<FormModel>();

  const mergedReset = () => {
    handleReset();
    if (onReset) onReset();
  };

  return (
    <Form>
      <h2 className="text-center">Create a scroll wow:</h2>
      <div className="form-item text-center">
        <label htmlFor="gifText">Your text</label>
        <Field
          type="text"
          name="gifText"
          className="input input-text text-center"
          autoFocus
        />
      </div>
      <div className="row justify-content-center">
        <div className="col col-auto">
          <div className="row align-items-center">
            <div className="col-auto" style={{ paddingRight: 0 }}>
              <Field
                type="color"
                name="textColor"
                className="input input-color"
              />
            </div>
            <label htmlFor="textColor" className="col">
              Text
            </label>
          </div>
        </div>
        <div className="col col-auto">
          <div className="row align-items-center">
            <div className="col-auto" style={{ paddingRight: 0 }}>
              <Field
                type="color"
                name="bgColor"
                className="input input-color"
              />
            </div>
            <label htmlFor="bgColor" className="col">
              Background
            </label>
          </div>
        </div>
        <div className="col col-auto">
          <CustomSelect
            name="size"
            label="Size"
            options={{ sm: "Small", md: "Medium", lg: "Large" }}
            hideLabel
          />
        </div>
        {/* <div className="col">
          <CustomSelect
            name="typeface"
            label="Typeface"
            options={typeFaceOptions}
            hideLabel
          />
        </div> */}
      </div>
      <div className="form-actions text-center">
        <button
          type="button"
          className="btn btn-secondary"
          disabled={isSubmitting}
          onClick={mergedReset}
        >
          Clear
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={!isValid || isSubmitting}
        >
          <Loading isLoading={isSubmitting} /> Makey makey
        </button>
      </div>
    </Form>
  );
};

export default FormInner;
