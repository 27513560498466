import React from "react";

import { Layout, Form } from "components";

function App() {
  return (
    <Layout>
      <Form />
    </Layout>
  );
}

export default App;
