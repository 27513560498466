import axios from "axios";

export type Size = "sm" | "md" | "lg";

type ImgParams = {
  text: string;
  bgColor?: string;
  color?: string;
  size?: Size;
};

const sizeMap: { [key in Size]: number } = {
  sm: 64,
  md: 112,
  lg: 200,
};

export const apiBase =
  process.env.NODE_ENV === "development"
    ? "/api/v1"
    : "https://api.scroll-wow.com/api/v1";

const ENDPOINT = `${apiBase}/text`;

export const fetchImage: (params: ImgParams) => Promise<string | null> =
  async ({ text, bgColor, color, size }) => {
    const params = {
      text: text,
      color: color,
      bgcolor: bgColor,
      size: size ? sizeMap[size] : sizeMap["lg"],
    };
    try {
      const response = await axios
        .post(ENDPOINT, params, {
          responseType: "blob",
          headers: { Accept: "image/gif" },
        })
        .then((r) => r.data);
      // const arrayBuffer = await response.arrayBuffer();
      return URL.createObjectURL(response);
    } catch (err) {
      console.error(`Cannot fetch data from image service. Error: ${err}`);
      return null;
    }
  };
