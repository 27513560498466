import React from "react";

import "./Header.scss";

const HeaderComponent: React.FC = () => {
  return (
    <header className="header-page">
      <div className="site-title h1">
        <span className="carrot">Scroll Wow!</span>
      </div>
      <div className="site-tagline">Say it louderer</div>
    </header>
  );
};

export default HeaderComponent;
