import { object, string, TestFunction } from "yup";
import { Message } from "yup/lib/types";

const validColorError: Message = (path) => `${path} is not a valid hex color`;
const validColorTest: TestFunction<string | undefined> = (value) =>
  value?.match("^#[a-zA-Z0-9]{6}$")?.length ? true : false;

export const formSchema = object().shape({
  gifText: string().required("Please enter text to create a scroll wow."),
  textColor: string().test("is-hex-color", validColorError, validColorTest),
  bgColor: string().test("is-hex-color", validColorError, validColorTest),
  typeface: string(),
});