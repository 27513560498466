import React from "react";

import { Formik, FormikHelpers } from "formik";

import { FormModel } from "./Form.model";
import { formSchema } from "./Form.schema";
import Form from "./Form";

import { fetchImage } from "services/scrollWowSvc";
import { ImageDisplay } from "components/ImageDisplay";

const initialTexts = [
  "You look fantastic!",
  "You're gorgeous.",
  "Brilliant!",
  "Yummy",
  "Luscious",
  "*wink*",
  "Yes pls!",
  "Woooow",
  "Get real",
  "No",
  "Yes",
  "Not likely",
  "Duh",
  "Really?",
  "Need it.",
  "Want it.",
  "Stop",
  "Good vibes...",
];
const initialBgs = [
  "#ed40c6",
  "#3ad8b6",
  "#933ad8",
  "#438bfc",
  "#fc7353",
  "#eff00a",
];

const Component: React.FC = () => {
  const [image, setImage] = React.useState<string>(); // base64 encoded image
  const [text, setText] = React.useState<string>("your-scroll-wow");

  const initialValues: FormModel = {
    gifText: initialTexts[Math.floor(Math.random() * initialTexts.length)],
    textColor: "#ffffff",
    bgColor: initialBgs[Math.floor(Math.random() * initialBgs.length)],
    typeface: "classic",
    size: "md",
  };

  const handleSubmit = async (
    values: FormModel,
    actions: FormikHelpers<FormModel>
  ) => {
    console.log(values);
    const cachedValues = values;
    try {
      const img = await fetchImage({
        text: values.gifText,
        bgColor: values.bgColor,
        color: values.textColor,
        size: values.size,
      });
      // const imgUrl = URL.createObjectURL(img);
      setImage(img || undefined);
      setText(values.gifText);
    } catch (err) {
      console.error("No fetchy", err);
      setText("your-scroll-wow");
    } finally {
      actions.setSubmitting(false); // done either way
      actions.setValues(cachedValues); // We don't want to lose our VALUES!
    }
  };

  const resetImage = () => {
    setImage(undefined);
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validationSchema={formSchema}
        initialValues={initialValues}
        validateOnChange
        validateOnBlur
        validateOnMount
        onSubmit={handleSubmit}
      >
        <Form onReset={resetImage} />
      </Formik>
      <ImageDisplay image={image} text={text} />
    </React.Fragment>
  );
};

export default Component;
