import React from "react";

import "./ImageDisplay.scss";

import { ReactComponent as Pointy } from "assets/pointy.svg";

import { BubbleLink } from "components";

export type Props = {
  image: string | undefined;
  text: string;
};

const PointyFinger: React.FC<{ reverse?: boolean }> = ({ reverse = false }) => {
  return (
    <div style={reverse ? { transform: "scaleX(-1)" } : undefined}>
      <Pointy
        className="finger-pointy"
        width="100"
        height="100"
        fill="currentColor"
      />
    </div>
  );
};

const ImageDisplayComponent: React.FC<Props> = ({ image, text }) => {
  const textAsFilename =
    text
      .replace(/[^\w ]/g, "")
      .replace(/\W/g, "-")
      .toLocaleLowerCase() + ".gif";
  return image ? (
    <div className="image-container">
      <div className="aside">
        <PointyFinger />
      </div>
      <div className="image">
        <img src={image} alt="Generated" title="Your wowie wow" />
        <div className="dl-link">
          <BubbleLink href={image} color="pop" download={textAsFilename}>
            <span>⬇</span>
            <span>Get it!</span>
          </BubbleLink>
        </div>
      </div>
      <div className="aside">
        <PointyFinger reverse />
      </div>
    </div>
  ) : null;
};

export default ImageDisplayComponent;
